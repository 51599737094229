// @tailwind base;
@tailwind components;
@tailwind utilities;
// @tailwind variants;

@import './vars.scss';

html, body{
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialised;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a{
  @apply text-primary no-underline;

  &:active, &:hover{
    @apply text-primary;
  }
}

.bgr-grey{
  background-color: $lightgrey;
}

.container{
  padding-left: 20px;
  padding-right: 20px;
  &.max-content{
    max-width: $maxWidth;
    margin: 0 auto;
  }
}

.loading-global{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  .loading-icon{
    font-size: 25px;
  }
}

.draggable-item{
  @apply h-24 w-24 flex justify-center items-center bg-white rounded-sm overflow-hidden;

  &:first-child{
    position: relative;

    &:after{
      content: 'Главная';
      position: absolute;
      top: 5px;
      left: 5px;
      font-size: 11px;
      font-weight: 500;
      color: $white;
      padding: 2px 5px;
      border-radius: 5px;
      z-index: 1;

      @apply bg-primary;
    }
  }

  .ant-btn{
    display: none;
  }

  &:hover{
    @apply relative;

    .ant-btn{
      display: block;
    }
  }

  .ant-ribbon{
    display: none;
  }
}

.dragging-item{
  position: fixed !important;
  z-index: 9999;

  .draggable-item{
    &:after{
      display: none;
    }
  }
}

.dropdown-fullwidth{
  width: 100%;

  button{
    &:first-child{
      width: 100%;
    }
  }

}

.button-outline{
  padding: 8px 17px;
  border: 1px solid #E7E7E7;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  @apply rounded-xl text-black font-normal text-lg leading-tight bg-transparent gap-2 transition-normal;

  img{
    @apply transition-normal;
    width: 20px;
    height: 20px;
  }

  svg{
    color: '#000';
    margin-right: 10px;
  }

  &:hover{
    @apply text-white border-primary bg-primary;
    transform: translateY(-2px);

    // img filter to make svg white
    img{
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }

    svg{
      color: '#fff';
    }
  }

  &.active{
    border-color: #0E86FF;
    @apply bg-primary text-white;

    img{
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }
  }
}

.waves-y{
  --mask:
    radial-gradient(67.08px at 50% 90.00px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
    radial-gradient(67.08px at 50% -60px,#0000 99%,#000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
    radial-gradient(67.08px at 50% calc(100% - 90.00px),#000 99%,#0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
    radial-gradient(67.08px at 50% calc(100% + 60.00px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);

  // on mobiles
  @media (max-width: $md){
    --mask:
    radial-gradient(22.36px at 50% 30.00px,#000 99%,#0000 101%) calc(50% - 20px) 0/40px 51% repeat-x,
    radial-gradient(22.36px at 50% -20px,#0000 99%,#000 101%) 50% 10px/40px calc(51% - 10px) repeat-x,
    radial-gradient(22.36px at 50% calc(100% - 30.00px),#000 99%,#0000 101%) calc(50% - 20px) 100%/40px 51% repeat-x,
    radial-gradient(22.36px at 50% calc(100% + 20.00px),#0000 99%,#000 101%) 50% calc(100% - 10px)/40px calc(51% - 10px) repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
  }

}

.waves-top{
  --mask:
    radial-gradient(67.08px at 50% 90.00px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 100%,
    radial-gradient(67.08px at 50% -60px,#0000 99%,#000 101%) 50% 30px/120px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);

  // on mobiles
  @media (max-width: $md){
    --mask:
    radial-gradient(22.36px at 50% 30.00px,#000 99%,#0000 101%) calc(50% - 20px) 0/40px 100%,
    radial-gradient(22.36px at 50% -20px,#0000 99%,#000 101%) 50% 10px/40px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
  }
}

.my-0-important-preview{
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &:first-child{
    margin-top: 0 !important;
  margin-bottom: 0 !important;

  }
}

// .collapsible-div{
//   transition: all 0.3s ease-in-out;

//   // allow up to 100px of content to be shown, apply gradient (transparent to white) to the bottom
//   &.collapsed{
//     max-height: 200px;
//     min-height: 100px;
//     overflow: hidden;
//     position: relative;

//     &:after{
//       content: '';
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       width: 100%;
//       height: 100px;
//       background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
//     }
//   }

//   &.expanded{
//     max-height: 100%;
//     overflow: visible;

//     .ant-btn{
//       display: none;
//     }
//   }

//   &.expanded, &.collapsed{
//     transition: all 0.3s ease-in-out;
//   }

// }

@import './components/layout';
@import './components/swiper';