.jeyran-swiper{
  .mobile-slider::-webkit-scrollbar {
    display: none;
  }

  .custom-pagination {
    display: flex;
    justify-content: center;
    gap: 12px;
    width: fit-content !important;
    padding: 20px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.5px);
    margin: 0 auto 60px auto;
    left: 0;
    right: 0;
    bottom: 0;
    scale: .7;
  }
  
  /* dots */
  
  .dot {
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    cursor: pointer;
    pointer-events: all;
    transition: all 0.2s ease-in-out;
  }
  
  .dot-active {
    width: 60px;
    border-radius: 100px;
    background-color: #949494;
    position: relative;
    cursor: auto;
  }
  
  .dot-active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background-color: #fff;
    border-radius: 100px;
    animation-duration: 5s;
    animation-name: slidein;
    animation-fill-mode: forwards;
  }
  
  @keyframes slidein {
    from {
      width: 20%;
    }
    to {
      width: 100%;
    }
  }
  
  @media (max-width: 686px) {
    .custom-pagination {
      gap: 8px;
      padding: 8px;
    }
    .dot {
      width: 8px;
      height: 8px;
    }
    .dot-active {
      width: 40px;
    }
  }
}