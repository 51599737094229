.container{
  width: 100%;

  &.content-max{
    margin-left: auto;
    margin-right: auto;
    max-width: $maxWidth;
  }
}

.global-layout{
  min-height: 100vh;
  background: $white;
  position: relative;
}

.ant-form-item{
  margin-bottom: 5px;
}

.site-layout .site-layout-background {
  background: $white;
}

.ant-layout-header{
  padding-left: 0;
  padding-right: 0;

  .menu-layout{
    // width: auto;
    margin: 0 20px;
  }

  .logo-top{
    display: block;
    overflow: auto;
    transition: all .67s ease-in-out;
    min-width: 134px;
    width: 134px;
    height: 34px;
    margin-right: 20px;
  
    img{
      display: block;
      width: 100%;
    }
  }

}

.site-header{
  background: $white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px !important;
}

.site-page-header{
  margin: 20px 0 !important;
  padding: 0 !important;
}

.loading-global{
  width: 100%;
  max-height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .loading-icon{
    font-size: 34px;
    color: rgba($black, .2);
  }
}