.container{
  @apply w-full grid grid-cols-12 gap-6;

  .card{
    @apply transition-normal;

    &.cta{
      &:hover{
        .img{
          @apply scale-105;
        }
        .label{
          @apply text-primary;
        }
      }

    }

    .img{
      transition: all 1s ease-in-out;
      @apply block w-full aspect-square object-cover object-center;
    }

    .bluelabel{
      @apply w-full uppercase absolute z-10 bottom-0 left-0 right-0 bg-primary bg-opacity-80 p-3 md:px-5 pb-12 text-white font-medium text-xl;
    }

    .label{
      @apply block transition-normal w-full uppercase font-medium text-black text-xl;
    }

    .subtitle{
      @apply block w-full font-normal text-black text-lg md:text-base mt-3;

      &.left{
        @apply text-left;
      }

      &.center{
        @apply text-center;
      }

      &.right{
        @apply text-right;
      }
    }
  }
}