.container{
  width: 100%;

  .iconContainer{
    background: url('./icon-cover.svg') center center no-repeat;
    background-size: 100%;
    width: 30px;
    height: 30px;
    @apply flex justify-center items-center;

    img{
      width: 20px;
      height: 20px;
      display: block;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }
  }
}