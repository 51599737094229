.title{
  @apply w-full text-xl font-medium;

  &.base{
    @apply text-xl font-medium;
  }

  &.sm{
    @apply text-lg font-medium;
  }

  &.lg{
    @apply text-2xl font-medium;
  }
}

.subtitle{
  @apply block w-full text-base font-medium;
}