.container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner{
    @apply text-5xl text-primary;
  }

  &.compact{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: transparent;

    .spinner{
      @apply text-3xl text-primary;
    }
  }
}