.title{
  @apply w-full text-lg font-medium uppercase my-5;
}

.subtitle{
  @apply w-full text-base font-medium uppercase my-3 mb-5;
}

.copy{
  @apply w-full font-normal;

  h2{
    @apply w-full text-lg font-medium uppercase my-2;
  }

  h3{
    @apply w-full text-lg font-medium uppercase my-2;
  }

  h4{
    @apply w-full text-lg font-medium uppercase my-2;
  }

  p, ul, ol, table, blockquote, pre, figure {
    @apply w-full font-normal text-center text-base my-5;
  }

  &.left{
    p{
      @apply text-left;
    }
  }
  &.right{
    p{
      @apply text-right;
    }
  }
  &.center{
    p{
      @apply text-center;
    }
  }

  table{
    @apply w-full my-5 text-base md:text-lg text-left;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    thead{
      @apply bg-gray-200;
    }
    th{
      @apply px-4 py-2 text-left text-base md:text-lg font-semibold;
    }
    td{
      @apply border px-4 py-2;
    }
  }

  ul, ol{
    @apply w-full text-left my-2 text-lg;
  }

  ul{
    @apply list-disc list-inside;

    li{
      @apply my-0;
    }
  }

  ol{
    @apply list-decimal list-inside;

    li{
      @apply my-2;
    }
  }

  a{
    @apply text-blue-500;
  }

  strong, b{
    @apply font-bold;
  }

  em, i{
    @apply italic;
  }

  blockquote{
    @apply border-l-4 border-gray-400 italic pl-4 text-lg;
  }
}