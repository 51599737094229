.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .logo{
    display: block;
    width: 80%;
    max-width: 150px;
    margin: 20px auto;
  }
}